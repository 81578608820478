.home-hookah-image {
  width: 50%;
  height: auto;
  display: inline-block;

  img {
    width: 100%;
    height: 100%;
  }

  span {
    position: relative;
    bottom: 8%;
    z-index: 20;
    color: white;
    font-size: 30px;
  }
}

.home-hookah-container {
  display: flex;
  flex-wrap: wrap
}

@media screen and (max-width: 770px) {
  .home-hookah-image {
    width: 100%
  }
}