.hookah-intro,
.hookah-table-label {
  font-family: $font-family-hookah;
}

.hookah-intro {
  padding-bottom: 15px;
  font-size: 20px;
  line-height: 32px;
}

/*.hookah-items-list {
  padding-left: 0;
  list-style-type: none;
  font-family: $font-family-hookah;
}*/

.hookah-items-list-ordered {
  padding-left: 20px;
}

.hookah-table {
  font-weight: 400;

  td {
    padding: 16px 0;
  }
}
