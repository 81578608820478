.menu-container {
  height: 100%;

  .container {
    margin-top: 64px;
  }
}

.menu-section-header {
  margin: 0;
}

.menu-section {
  margin: 0 16px 40px 16px;
  font-weight: 400;
}

.menu-section-item {
  padding-top: 24px;
  border-bottom: 1px dotted darken($body-bg, 20%);

  //.clearfix();
}

.menu-section-item-name {
  display: inline-block;
  max-width: 85%;
}

.menu-section-item-name,
.menu-section-item-price {
  position: relative;
  top: 8px;
  padding: 0 8px;
  background: $body-bg;
}

.menu-section-item-price {
  float: right;
  margin-left: 16px;
}

.menu-section-list {
  margin-bottom: 0;
}
