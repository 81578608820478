@import "~bootstrap/scss/functions";
@import "mixins";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/utilities";
//@import "~bootstrap/scss/responsive-utilities.scss";
@import "~bootstrap/scss/tables.scss";
//@import "~font-awesome/scss/font-awesome.less";

/** {
  .box-sizing(border-box);
}
*:before,
*:after {
  .box-sizing(border-box);
}*/

html,
body,
#root {
  /* width: 100%;*/
  height: 100%;
  margin: 0 !important;
}

body {
  background-color: $body-bg;
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: 500;
  line-height: 22px;
}

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

header,
a,
article {
  transition: all 500ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
}

p {
  margin: 0;
  padding-top: 10px;
}

.fa-fixed-width {
  width: 25px;
  text-align: center;
}

.sr-only {
  position: absolute;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
