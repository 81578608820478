.ribbon {
  position: relative;
  display: inline-block;
  margin: 0 auto;
  padding: 30px 30px 25px 30px;
  text-align: center;
  color: #ffffff;
  background: $brand-primary;
  font-size: 16px !important;

  .ribbon-subtitle {
    font-size: 14px;
  }

  &:before,
  &:after {
    position: absolute;
    z-index: -1;
    bottom: -1em;
    display: block;
    content: "";
    border: 1.9em solid darken($brand-primary, 10%);
  }

  &:before {
    left: -2.37em;
    border-right-width: 1.5em;
    border-left-color: transparent;
  }

  &:after {
    right: -2.37em;
    border-right-color: transparent;
    border-left-width: 1.5em;
  }

  &.hide-edges:before,
  &.hide-edges:after {
    display: none;
  }

  &.hide-bottom-edges span:before,
  &.hide-bottom-edges span:after {
    display: none;
  }


  .ribbon-content {
    font-size: 28px;
    font-weight: 500;

    &:before,
    &:after {
      position: absolute;
      bottom: -1em;
      display: block;
      content: "";
      border-style: solid;
      border-color: darken($brand-primary, 20%) transparent transparent transparent;
      font-size: 16px;
    }

    &:before {
      left: 0;
      border-width: 1em 0 0 1em;
    }

    &:after {
      right: 0;
      border-width: 1em 1em 0 0;
    }
  }
}

.ribbon-container {
  width: 100%;
  text-align: center;
}
