@import "~bootstrap/scss/variables";

$brand-primary: #8b5ba3;
$link-color: #23527c;
$body-bg: #f2f2f2;
$font-family-base: Roboto, Arial, sans-serif;
$font-family-header-quote: Kurale, Arial, sans-serif;
$font-family-hookah: Kurale, Arial, sans-serif;
$font-size-base: 14px;

$header-height: 38.2%;
$header-height-big: 61.2%;
//$header-cover-background: fade(darken($brand-primary, 30%), 40%);
$header-cover-background: rgba(55, 36, 65, .4);
$header-quote-font-size: 45px;
$header-quote-author-font-size: 13px;
$header-quote-color: #ffffff;
$navigation-top-margin: 32px;
$navigation-color: #ffffff;
$navigation-bg-color-hover: darken($brand-primary, 20%);
$navigation-bg-color: fade(darken(desaturate($brand-primary, 35%), 25%), 60%);
$navigation-border-radius: 22px;
$navigation-logotype-size: 50px;
$navigation-logotype-border: 4px;
$navigation-link-width: 100px;
$navigation-link-count: 4;
$navigation-before-logotype: 40px;
$navigation-width: calc($navigation-link-width * $navigation-link-count + $navigation-before-logotype);
$navigation-logotype-left: calc($navigation-width / 2 - $navigation-logotype-border - $navigation-logotype-size / 2);
$navigation-mobile-width: 220px;
$navigation-mobile-transition-duration: 0.3s;

$event-title-color: #ffffff;
$event-title-background: $brand-primary;
$event-panel-height: 42px;
$event-panel-background: #f9f9f9;
$event-panel-border-color: #dfdfdf;
$event-panel-font-color: #808080;
$event-content-background: #ffffff;

$graditude-font-color: #808080;
$graditude-links-font-color: $brand-primary;

$about-slide-nav-font-color: #ffffff;
$about-slide-nav-background: fade(#000000, 40%);
$about-nav-item-color: #ffffff;
$about-background-color: #ffffff;
$about-price-font-size: 22px;

$feed-header-color: #808080;

$footer-logotype-size: 100px;
$footer-time-font-color: darken($brand-primary, 20%);
$footer-background: $brand-primary;
$footer-base-font-color: #ffffff;

$rent-control-border-color: #cccccc;
$rent-control-active-border-color: #000000;
$rent-description-font-color: #ffffff;
$rent-room-info-font-size: 16px;
$rent-room-description-background: fade(#000000, 40%);
$rent-contact-font-color: #000000;

$address-block-background: #ffffff;
$address-block-width: 250px;
$contacts-house-font-size: 20px;
$contacts-how-to-go-color: #ffffff;
$contacts-link-color: $link-color;
$contacts-social-container-background: #7669cb;
$contacts-social-font-size: 43px;
$contacts-social-background: #aa9dff;
$contacts-social-size: 85px;
$contacts-social-color: #ffffff;
$contacts-partners-container-background: #4a5b53;
$contacts-partners-font-color: #77a791;
$contacts-blazon-font-color: #979797;
$contacts-street-sign-size: 165px;

$ribbon-content-font-size: 24px;

$screen-xs-max: map-get($container-max-widths, 'lg');
